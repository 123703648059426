import React, { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

import Loading from "../components/Loading";

const Login = lazy(() => import("../containers/Login"));
const AuthenticatorRouter = lazy(() => import("./AuthenticatorRouter"));
const MasterLayout = lazy(() => import("../containers/MasterLayout"));
const Dashboard = lazy(() => import("../containers/Dashboard"));
const AccountConfig = lazy(() => import("../containers/AccountConfig"));
const SupportExpert = lazy(() => import("../containers/SupportExpert"));
const UserProfile = lazy(() => import("../containers/UserProfile"));
const Professionals = lazy(() => import("../containers/Professionals"));
const Plans = lazy(() => import("../containers/Plans"));
const PaymentSuccess = lazy(() =>
  import("../containers/Payment/PaymentSuccess")
);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <React.Suspense fallback={<Loading />}>
        <Login />
      </React.Suspense>
    ),
  },
  {
    path: "/:token",
    element: (
      <React.Suspense fallback={<Loading />}>
        <Login />
      </React.Suspense>
    ),
  },
  {
    path: ":token/dashboard",
    element: (
      <React.Suspense fallback={<Loading />}>
        <AuthenticatorRouter masterlayout>
          <Dashboard />
        </AuthenticatorRouter>
      </React.Suspense>
    ),
  },
  {
    path: ":token/settings",
    element: (
      <React.Suspense fallback={<Loading />}>
        <AuthenticatorRouter masterlayout>
          <AccountConfig />
        </AuthenticatorRouter>
      </React.Suspense>
    ),
  },
  {
    path: ":token/support",
    element: (
      <React.Suspense fallback={<Loading />}>
        <AuthenticatorRouter masterlayout>
          <SupportExpert />
        </AuthenticatorRouter>
      </React.Suspense>
    ),
  },
  {
    path: ":token/profile",
    element: (
      <React.Suspense fallback={<Loading />}>
        <AuthenticatorRouter masterlayout>
          <UserProfile />
        </AuthenticatorRouter>
      </React.Suspense>
    ),
  },
  {
    path: ":token/professionals/:slugname",
    element: (
      <React.Suspense fallback={<Loading />}>
        <MasterLayout>
          <UserProfile />
        </MasterLayout>
      </React.Suspense>
    ),
  },
  {
    path: ":token/professionals",
    element: (
      <React.Suspense fallback={<Loading />}>
        <MasterLayout>
          <Professionals />
        </MasterLayout>
      </React.Suspense>
    ),
  },
  {
    path: ":token/plans",
    element: (
      <React.Suspense fallback={<Loading />}>
        <AuthenticatorRouter masterlayout>
          <Plans />
        </AuthenticatorRouter>
      </React.Suspense>
    ),
  },
  {
    path: "payment-success",
    element: (
      <React.Suspense fallback={<Loading />}>
        <AuthenticatorRouter masterlayout>
          <PaymentSuccess />
        </AuthenticatorRouter>
      </React.Suspense>
    ),
  },
]);

export default router;
